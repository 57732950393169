<template>
  <div>
    <div class="headerbox">
      <div class="maincontent head_container">
        <div class="top">
          <div class="logo">
            <router-link to="/index">
              <img class="pclogo" src="../../assets/logo.png">
              <img class="mlogo" src="../../assets/mlogo.png">
            </router-link>
          </div>
          <div class="right">
            <ul>
              <li><router-link to="/index" :class=" currentindex === 1 ? 'active':'' ">品牌故事</router-link></li>
              <!-- <li><router-link to="/introduction" :class=" currentindex === 2 ? 'active':'' ">鲜冻海参</router-link></li> -->
              <li><router-link to="/dishes" :class=" currentindex === 3 ? 'active':'' ">名厨参谱</router-link></li>
              <li><router-link to="/contact">联系我们</router-link></li>
            </ul>
          </div>
          <div class="rightselect">
            <img v-if="!show" class="more" src="../../assets/more.png" alt="" @click="selectclick">
            <img v-else class="more" src="../../assets/close.png" alt="" @click="selectclick">
          </div>
        </div>
        <div class="menubox" v-show="show">
          <ul>
            <li><router-link to="/index" :class=" currentindex === 1 ? 'active':'' ">品牌故事</router-link></li>
            <!-- <li><router-link to="/introduction" :class=" currentindex === 2 ? 'active':'' ">鲜冻海参</router-link></li> -->
            <li><router-link to="/dishes" :class=" currentindex === 3 ? 'active':'' ">经典海参菜谱</router-link></li>
            <li><router-link to="/contacts">联系我们</router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="empty"></div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      show: false
    }
  },
  props: {
    currentindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    currentindex(newdata) {
      this.show = false
    }
  },
  mounted(){
  },
  methods:{
    selectclick() {
      this.show = !this.show
    }
  }
}
</script>

<style lang="scss" scoped>
.headerbox {
  background: #D62326;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: 999999;
}
// .empty {
//   height: 130px;
// }
.head_container {
  height: 130px;
  background: #D62326;
  position: relative;
  .top {
    display: flex;
    justify-content: space-between;
    .logo {
      width: 169px;
      height: 169px;
      background: #fff;
      a {
        display: block;
        width: 169px;
        height: 169px;
        img {
          width: 100%;
          height: 100%;
        }
        .pclogo {
          display: block;
        }
        .mlogo {
          display: none;
        }
      }
    }
    .rightselect {
      display: none;
    }
    .right {
      width: 340px;
      ul {
        display: flex;
        margin-top: 84px;
        li{
          height: 20px;
          padding: 0 18px;
          a {
            display: block;
            font-size: 12px;
            height: 32px;
            line-height: 32px;
            color: #fff;
            font-weight: 700;
          }
        }
        .active {
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
  .menubox {
    display: none;
  }
}
@media screen and(max-width: 768px){
  .headerbox {
    background: #D62326;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
  }
  .empty {
    height: 56px;
  }
  .head_container {
    height: 56px;
    position: relative;
  }
  .head_container .top .logo {width: 88px;height: 88px;position: absolute;left:0;z-index: 10;}
  .head_container .top .logo a {width: 88px;height: 88px;}
  .head_container .top .logo a .pclogo {display: none;}
  .head_container .top .logo a .mlogo {display: block;width: 88px;height: 88px;}
  .head_container .top .right {
    display: none;
  }
  .head_container .top .rightselect {
    display: block;
    position: absolute;
    right:0;
    z-index: 10;
    .more {
      margin: 16px 24px 0 0;
    }
  }
  .head_container .menubox {
    position: absolute;
    top: 56px;
    left:0;
    right:0;
    display: block;
    background: #F9F8F4;
    padding:0 24px;
    ul {
      li {
        border-bottom: 1px solid #E0DFDB;
        line-height: 55px;
        text-align: center;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 16px;
          color: #603E2D;
        }
        .active {
          color: #D71718;
          font-weight: 700;
        }
      }
    }
  }

}
</style>

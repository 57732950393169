<template>
  <div id="app">
    <Headers :currentindex="cindex"/>
    <router-view/>
  </div>
</template>

<script>
import Headers from './views/components/header'
export default {
  components: {
    Headers
  },
  data() {
    return {
      cindex: 1
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/index') {
        this.cindex = 1
      } else if (to.path === '/introduction') {
        this.cindex = 2
      } else if (to.path === '/dishes') {
        this.cindex = 3
      } else if (to.path === '/contact') {
        this.cindex = 4
        this.$router.push('/dishes')
        window.scrollTo(0,3900)
      } else if (to.path === '/contacts') {
        this.cindex = 5
        this.$router.push('/dishes')
        window.scrollTo(0,100000)
      }
		}
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000;
}
html,body,div,span,h1,h2,h3,h4,h5,h6,p,img,b,i,dl,dt,dd,ol,ul,li {
	margin:0;
	padding:0;
	border:0;
}
ul,ol,li {
	list-style-type:none;
}
a {
	color:#000;
	text-decoration:none;
	outline:0;
}
a:hover {
	text-decoration:none;
}
.maincontent {
  width: 1180px;
  margin:0 auto;
}
@media screen and(max-width: 1180px){
  .maincontent {
    width: 1000px;
  }
}
@media screen and(max-width: 768px){
  .maincontent {
    width: 100%;
  }
}
</style>

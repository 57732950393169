import 'babel-polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$baseurl = process.env.VUE_APP_BASE_API

Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('@/views/index.vue')
  },
  {
    name: 'gamenews',
    path: '/gamenews',
    component: () => import('@/views/gamenews.vue')
  },
  {
    name: 'introduction',
    path: '/introduction',
    component: () => import('@/views/introduction.vue')
  },
  {
    name: 'dishes',
    path: '/dishes',
    component: () => import('@/views/dishes.vue')
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/contact.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
